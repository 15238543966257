// 是否是增值税发票
export const isVatInvoice = (type) => {
  return (
    [
      "EleInvoice",
      "VolInvoice",
      "VehInvoice",
      "EVehInvoice",
      "SpecInvoice",
      "GenerInvoice",
      "EleTollInvoice",
      "EleSpecInvoice",
      "UsedCarInvoice",
      "EleTrainInvoice",
      "ElePlaneInvoice",
      "TransportInvoice",
      "EleUsedCarInvoice",
      "FullEleSpecInvoice",
      "FullEleGenerInvoice",
      "PaPerUsedCarInvoice",
      "FullElePaperSpecInvoice",
      "FullElePaperGenerInvoice",
      "PaPerMotorSaleUnifiedInvoice",
    ].indexOf(type) != -1
  );
};

// 是否不是增值税发票
export const isNonVatInvoice = (type) => {
  return (
    [
      "TollInvoice",
      "TaxiInvoice",
      "PlaneInvoice",
      "TrainInvoice",
      "QuotaInvoice",
      "PassengerInvoice",
      "GeneralMachineInvoice",
    ].indexOf(type) != -1
  );
};

// 是否是增值税发票清单
export const isVatInvoiceList = (type) => {
  return ["VatInvoiceList"].indexOf(type) != -1;
};

// 是否是数电增值税发票
export const isSdVatInvoice = (type) => {
  return (
    [
      "EleTrainInvoice",
      "ElePlaneInvoice",
      "FullEleSpecInvoice",
      "FullEleGenerInvoice",
      "FullElePaperSpecInvoice",
      "FullElePaperGenerInvoice",
    ].indexOf(type) != -1
  );
};

// 是否是数电增值税电票
export const isSdVatEInvoice = (type) => {
  return (
    [
      "EleTrainInvoice",
      "ElePlaneInvoice",
      "FullEleSpecInvoice",
      "FullEleGenerInvoice",
    ].indexOf(type) != -1
  );
};

// 是否是税控增值税发票
export const isSkVatInvoice = (type) => {
  return (
    [
      "EleInvoice",
      "VolInvoice",
      "VehInvoice",
      "EVehInvoice",
      "SpecInvoice",
      "GenerInvoice",
      "EleTollInvoice",
      "EleSpecInvoice",
      "UsedCarInvoice",
      "TransportInvoice",
      "EleUsedCarInvoice",
    ].indexOf(type) != -1
  );
};

// 是否是电子增值税发票
export const isDzVatInvoice = (type) => {
  return (
    [
      "EleInvoice",
      "EleSpecInvoice",
      "EleTollInvoice",
      "ElePlaneInvoice",
      "EleTrainInvoice",
      "FullEleSpecInvoice",
      "FullEleGenerInvoice",
    ].indexOf(type) != -1
  );
};

// 是否是纸质增值税发票
export const isZzVatInvoice = (type) => {
  return (
    [
      "VolInvoice",
      "VehInvoice",
      "SpecInvoice",
      "EVehInvoice",
      "GenerInvoice",
      "UsedCarInvoice",
      "TransportInvoice",
      "EleUsedCarInvoice",
      "PaPerUsedCarInvoice",
      "FullElePaperSpecInvoice",
      "FullElePaperGenerInvoice",
      "PaPerMotorSaleUnifiedInvoice",
    ].indexOf(type) != -1
  );
};

// 是否是纸票数电增值税发票
export const isZzSdInvoice = (type) => {
  return (
    ["FullElePaperSpecInvoice", "FullElePaperGenerInvoice"].indexOf(type) != -1
  );
};

// 查验发票时金额是否是必填
export const isAmountRequired = (type) => {
  return (
    [
      "VehInvoice",
      "SpecInvoice",
      "EleSpecInvoice",
      "FullElePaperSpecInvoice",
    ].indexOf(type) != -1
  );
};

// 查验发票时价税合计是否是必填
export const isSumAmountRequired = (type) => {
  return (
    [
      "UsedCarInvoice",
      "EleTrainInvoice",
      "ElePlaneInvoice",
      "FullEleSpecInvoice",
      "FullEleGenerInvoice",
      "PaPerUsedCarInvoice",
    ].indexOf(type) != -1
  );
};

// 查验发票时校验码是否是必填
export const isVerifyCodeRequired = (type) => {
  return (
    [
      "VolInvoice",
      "GenerInvoice",
      "EleTollInvoice",
      "FullElePaperGenerInvoice",
    ].indexOf(type) != -1
  );
};
